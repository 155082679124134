<template>
  <div>
    <div
      class="transition duration-200 ease-in-out payment-card"
      :class="{ active: active }"
    >
      <div class="flex items-center justify-between p-4">
        <div>
          <div class="tg-muted-text-md" :class="{ 'text-oBlack': active }">
            {{ title }}
          </div>

          <div class="h-2" v-if="active">
            <span class="tg-muted-text-sm" variant="three">
              {{
                $t(
                  'components.riderDetailsManagement.diveIntoTab.paymentMethod.wallet.currentPaymentMethod'
                )
              }}
            </span>
          </div>
        </div>
        <div>
          <i v-if="active" class="fas fa-check-circle text-oGreen text-36px" />
        </div>
      </div>

      <section class="subheader">
        <slot name="subheader"></slot>
      </section>

      <section class="payment-card-body">
        <slot name="body"></slot>
      </section>

      <section class="payment-card-footer">
        <slot name="footer"></slot>
      </section>
    </div>
  </div>
</template>

<script>
// import OtoTypography from '@/components/ui/OtoTypography'

export default {
  name: 'PaymentCardBaseBlock',
  props: {
    title: {
      type: [String, Number],
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    // OtoTypography,
  },
}
</script>

<style lang="scss" scoped>
.payment-card {
  width: 100%;
  min-height: 607px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;

  &.active {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
}
.text-36px {
  font-size: 36px;
}
</style>
